
import { defineComponent } from 'vue';

import ColumsDialog from '@/components/main/ColumnsDialog.vue';
import Filters from '@/components/main/Filters.vue';
import RowDetail from '@/components/main/RowDetail.vue';

import DeliveriesTable from '@/components/main/DeliveriesTable.vue';
import SaveFilter from '@/components/main/SaveFilter.vue';
import GlobalSearch from '@/components/main/GlobalSearch.vue';
import DownloadExcel from '@/components/main/DownloadExcel.vue';
import Spinner from '@/components/common/BasfSpinner.vue';
import BasfSwitch from '@/components/common/BasfSwitch.vue';
import { TableColumns } from '@/constants/tableColumns';
import FiltersUtils from '@/scripts/filtersUtils';

import { shipmentsList, TableColumn, SavedFilter, FilterSelection } from '@/typings';
import { FilterType, FilterAction, FilterAdditional } from '@/typings/enums';
import { FilterLabels } from '@/constants';

import dayjs from 'dayjs';
import { Filter } from '@/services/model/user';

export default defineComponent({
  name: 'Main',
  inheritAttrs: false,
  components: {
    ColumsDialog,
    Filters,
    DeliveriesTable,
    RowDetail,
    SaveFilter,
    DownloadExcel,
    BasfSwitch,
    Spinner,
    GlobalSearch
  },
  data() {
    return {
      selectedRow: null as any,
      seeFollowShipments: false,
      dataLoaded: false,
      fetching: false,
      minColWidth: 40,
      patchTop: 0,
      enums: {
        FilterType
      },
      defaultColumns: TableColumns as TableColumn[],
      showColumnsDialog: false,
      showSaveFilterDialog: false,
      showExcelDialog: false,
      dialogKey: 0,
      fullView: false,
      initialHScroll: 0,
      restCoefic: 340,
      FilterLabels: FilterLabels,
      filtersVisualLimit: 0,
      filtersVisualLimitEnabled: true
    };
  },
  mounted() {
    this.fetchShipments();
  },
  computed: {
    dqTeamAuthorized(): boolean {
      return this.$store.getters.isDQTeamAuthorized;
    },
    selectedConditions(): Record<FilterType, FilterSelection[]> {
      return this.$store.state.filters.selectedConditions;
    },
    columns(): TableColumn[] {
      if (this.$store.state.userData) {
        return this.$store.state.userData.settings.theme.tableListColumns;
      }
      return [];
    },
    savedFilters(): SavedFilter[] {
      return this.$store.state.filters.savedFilters;
    },
    data(): shipmentsList[] {
      return this.$store.getters['shipments/getShipments'];
    },
    currentFilter() {
      const filters = this.savedFilters as SavedFilter[];
      return filters.find((f) => f.selected === true);
    },
    showDetails(): boolean {
      return this.$store.state.showDetails;
    },
    showFilters(): boolean {
      return this.$store.state.showFilters;
    },
    visibleColumns(): TableColumn[] {
      return this.columns.filter((column) => column.visible).sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    selectedConditionsFlattened(): any[] {
      return Object.values(this.selectedConditions).flat();
    },
    selectedConditionsLimited(): any[] {
      return this.filtersVisualLimit && this.filtersVisualLimitEnabled
        ? this.selectedConditionsFlattened.slice(0, this.filtersVisualLimit)
        : this.selectedConditionsFlattened;
    },
    canSave(): boolean {
      const selectedSavedFilter = this.savedFilters.find((f) => f.selected);

      if (selectedSavedFilter) {
        const savedselectedConditionsFlattened = Object.values(selectedSavedFilter.conditions).flat();

        const idsSaved = savedselectedConditionsFlattened.map((f: any) => f.filterId);

        return !(
          JSON.stringify(savedselectedConditionsFlattened) === JSON.stringify(this.selectedConditionsFlattened) &&
          this.selectedConditionsFlattened.every((filter) => idsSaved.includes(filter.filterId))
        );
      }

      return true;
    },
    filtersVisible(): boolean {
      return this.showFilters && !this.fullView;
    },
    numDqChanges(): number {
      return this.$store.getters['shipments/numberOfEdits'];
    }
  },
  methods: {
    async fetchShipments(withAlert = false) {
      this.seeFollowShipments = withAlert;

      // remember the current horizontal table scroll to restore it after data update
      const targetC: any = this.$refs.deliveriesTable;
      this.initialHScroll = targetC ? targetC.$refs.tableHeader.scrollLeft : 0;

      const followedDeliveries = this.$store.state.userData.followedDeliveries.map((m) => {
        return { searchTerm: m, label: null };
      });
      let toCheckConditions = FiltersUtils.createSaveObject(this.selectedConditions);
      // if with alert is activated, we inject a new condition to the search object to return the followed deliveries,
      // the reason we do this here, is because we want to keep the saved conditions intact, so just add them to the search when the button is on.
      if (withAlert) {
        toCheckConditions.push({
          fieldName: FilterAdditional.logisticTrackerID,
          value: followedDeliveries
        });
      }
      // create params object to send to api
      const sort = this.$store.state.shipments.sort;
      const data = {
        page: this.$store.state.shipments.page,
        size: this.$store.state.shipments.pageSize,
        sort: sort.field && sort.order ? `${sort.sortStr},${sort.order}` : '',
        conditions: toCheckConditions
      };
      this.fetching = true;
      this.$store.dispatch('SET_SHOW_DETAILS', false);
      await this.$store.dispatch('shipments/FETCH_SHIPMENTS', data);

      this.fetching = false;
      this.dataLoaded = true;

      await this.$nextTick();
      this.setRestCoefic();
    },
    applySort(val) {
      // replace column name for the specific sort value if exists
      const entireCol = this.columns.find((f) => f.field === val.field);
      const sortParam = entireCol && entireCol.sortStr ? entireCol.sortStr : val.field;
      const sort = {
        sortStr: entireCol ? sortParam : val.field,
        field: val.field,
        order: val.order
      };
      this.$store.commit('shipments/setSort', sort);
      this.fetchShipments();
    },
    setPageSize(val: number) {
      this.$store.commit('shipments/setPageSize', val);
      this.fetchData(false);
    },
    applyPage(val: number) {
      this.$store.commit('shipments/setPage', val);
      this.fetchData(false);
    },
    closeFilter() {
      this.$store.dispatch('SET_SHOW_FILTERS', false);
    },
    updateSelectedRow(row) {
      this.selectedRow = row;
    },
    itemTooltip(item): string {
      return item.selectedBox ? `${item.selectedBox.name}: ${item.name}` : `${item.title}: ${item.name}`;
    },
    closeDialog() {
      this.showColumnsDialog = false;
      this.dialogKey++;
    },
    resetToDefault() {
      this.saveTableSettings(this.defaultColumns);
    },
    refreshDialog() {
      this.dialogKey++;
    },
    async saveTableSettings(obj: boolean | TableColumn[] = false) {
      this.$store
        .dispatch(
          'SAVE_TABLE_SETTINGS',
          obj
            ? obj
            : this.columns.map((m) => {
                return { field: m.field, order: m.order, visible: m.visible, width: m.width };
              })
        )
        .then(() => {
          this.closeDialog();
          // just do a silent error if save fails on table.
        });
    },
    toggleFilters() {
      this.$store.dispatch('SET_SHOW_FILTERS', !this.showFilters);
      this.$store.dispatch('SET_SHOW_DETAILS', false);
    },
    fetchData(nosave = false) {
      if (!nosave) {
        // save filters conditions and other settings
        this.$store.dispatch('filters/SAVE_CURRENT_CONDITIONS', {
          conditions: this.selectedConditions,
          filter: this.currentFilter
        });
      }
      this.fetchShipments();
    },
    updateOrCreateFilter() {
      const current = this.savedFilters.find((f) => f.selected === true);
      if (current) {
        const prepOpb = {
          filterId: current.filterId,
          name: current.name,
          selected: true,
          conditions: this.selectedConditions
        };
        this.updateSavedFiltersData({ filter: prepOpb });
      } else {
        this.showSaveFilterDialog = true;
      }
    },
    updateFilter({ id, option, title, action, selectedBox = null as any }) {
      if (action === FilterAction.ADD && !this.selectedConditions[id].find((f) => f.id === option.id)) {
        // filtered to prevent duplicated additions
        this.selectedConditions[id].push({
          ...option,
          selectedBox,
          title,
          filterId: id
        });
      } else if (action != FilterAction.ADD) {
        const idxOption = this.selectedConditions[id].findIndex((opt) => opt.id === option.id);
        this.selectedConditions[id].splice(idxOption, 1);
      }

      this.fetchData();
    },
    updateDate({ startDate, endDate, option }) {
      const startDateFormatted = dayjs(startDate).format('DD/MM/YY');
      const endDateFormatted = dayjs(endDate).format('DD/MM/YY');
      this.selectedConditions[FilterType.TIME] = [
        {
          filterId: FilterType.TIME,
          ...option,
          date: [startDate, endDate],
          name: `${startDateFormatted} - ${endDateFormatted}`,
          title: option.name
        }
      ];
      this.fetchData();
    },
    applySavedFilter(id) {
      this.savedFilters.forEach((filter) => {
        filter.selected = filter.filterId === id;
      });

      const filterToApply = this.savedFilters.find((filter) => filter.selected) as SavedFilter;

      const newConditions = JSON.parse(JSON.stringify(filterToApply.conditions));
      this.$store.commit('filters/updateConditions', newConditions);

      this.fetchData();
    },
    removeFilter(item) {
      const idx = this.selectedConditions[item.filterId].findIndex((filter) => filter.id === item.id);
      this.selectedConditions[item.filterId].splice(idx, 1);

      // unselect filter if no conditions left - - Bug #266466
      if (this.selectedConditionsFlattened.length === 0) {
        this.resetFilter();
      }

      this.fetchData();
    },
    updateSavedFiltersData(data) {
      const indexToReplace = this.savedFilters.findIndex((f) => f.filterId === data.filter.filterId);

      const saveObj: Filter = {
        filterId: data.filter.filterId,
        name: data.filter.name,
        conditions: FiltersUtils.createSaveObject(data.filter.conditions)
      };

      this.$store.dispatch('filters/EDIT_FILTER', saveObj).then((response) => {
        if (response) {
          this.savedFilters[indexToReplace] = data.filter;
          this.applySavedFilter(data.filter.filterId);

          this.$toast.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'The filter conditions have been updated.',
            life: 3000
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'The changes could not be saved.',
            life: 3000
          });
        }
      });
    },
    resetFilter(refresh = true) {
      for (const property in this.selectedConditions) {
        this.selectedConditions[property] = [];
      }
      this.savedFilters.forEach((filter) => {
        filter.selected = false;
      });
      if (refresh) {
        this.fetchData();
      }
    },
    removeSavedFilter(id: string) {
      this.$store.dispatch('filters/DELETE_FILTER', id).then((response) => {
        if (response) {
          this.$store.commit('filters/deleteFilter', id);

          this.$toast.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'The filter has been deleted.',
            life: 3000
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'The item could not be deleted.',
            life: 3000
          });
        }
      });
    },
    duplicateFilter(id: string) {
      const filters = JSON.parse(JSON.stringify(this.savedFilters));
      const itemIndex = filters.findIndex((i) => i.filterId === id);

      const prepObj = {
        data: {
          filterId: null,
          name: this.newFilterName(filters[itemIndex].name),
          conditions: FiltersUtils.createSaveObject(filters[itemIndex].conditions)
        },
        alert: false // this goes as false as we do not enable notifications by default when duplicating
      };

      this.$store.dispatch('filters/ADD_FILTER', prepObj).then((response) => {
        if (response) {
          // get index of new item and select it
          this.applySavedFilter(response.filterId);
          this.$emit('close');
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error duplicating item.',
            life: 3000
          });
        }
      });
    },
    newFilterName(name: string) {
      // generate a filter name avoiding repeats

      let newName = name + ' Copy';
      const count = this.savedFilters.filter((f) => f.name.toUpperCase() === newName.toUpperCase()).length;
      if (count) {
        // name exists
        return this.newFilterName(newName + ' Copy');
      }
      return newName;
    },
    renameFilter(attr) {
      //validations
      const regEx = /^[0-9a-zA-Z ]+$/;

      if (!attr.newName || !regEx.test(attr.newName)) {
        this.$store.commit('showDialogAlert', {
          text: `Please enter a valid name. Only alphanumeric characters allowed`,
          header: 'Invalid name.',
          type: 'alert'
        });
        return;
      }

      if (
        this.savedFilters.find((f) => f.name && f.name.toUpperCase() === attr.newName.toUpperCase() && f.filterId !== attr.filter.filterId)
      ) {
        this.$store.commit('showDialogAlert', {
          text: `The name '${attr.newName}' already exists.`,
          header: 'Repeated name.',
          type: 'alert'
        });
        return;
      }

      const saveObj = {
        filterId: attr.filter.filterId,
        name: attr.newName,
        conditions: FiltersUtils.createSaveObject(attr.filter.conditions),
        alert: null
      };

      this.$store.dispatch('filters/EDIT_FILTER', saveObj).then((response) => {
        if (response) {
          // replace name locally
          this.savedFilters.forEach((filter) => {
            if (filter.filterId === attr.filter.filterId) {
              filter.name = attr.newName;
            }
          });

          this.$toast.add({
            severity: 'success',
            summary: 'Success!',
            detail: "The filter name has been changed to '" + attr.newName + "'.",
            life: 3000
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'The item could not be saved.',
            life: 3000
          });
        }
      });
    },
    toggleVisibleFilters() {
      this.filtersVisualLimitEnabled = !this.filtersVisualLimitEnabled;
      this.setRestCoefic();
    },
    async setRestCoefic() {
      // this function calculates how much height we have to rest from the table and filters component in order to fit into the screen
      // due to the complexity of the component, this operation could not be accomplished with CSS only
      // needs to be called any time the height above the component changes
      // value when no filters applied is: 340

      // calculate how many items to show to not overpass 3 lines
      this.filtersVisualLimit = 0;
      await this.$nextTick();
      const maxheight = 180; // value of cut-off position to show 3 lines
      const currentElements = document.querySelectorAll('.selected-filters__filter');
      const hasitem = Array.from(currentElements).findIndex((f: any) => f.offsetTop > maxheight);
      this.filtersVisualLimit = hasitem > 0 ? hasitem : 0;

      // adjust table height
      await this.$nextTick();
      const mainTableOfset = (this.$refs.mainTable as HTMLElement).offsetTop; // 168 usually
      const adjustLayer = 178;
      this.restCoefic = mainTableOfset + adjustLayer;
    },
    async applyDqChanges() {
      await this.$store.dispatch('shipments/SAVE_EDITED_DELIVERIES');
      this.fetchShipments();
    },
    discardDqChanges() {
      this.$store.commit('shipments/resetShipmentEdits');
    }
  }
});
