
import { defineComponent } from 'vue';
import { TransportType } from '@/typings/enums';
import { globalDateTimeFormat } from '@/constants';
import DetailLabel from '@/components/main/DetailLabel.vue';
import TimelineTable from '@/components/main/TimelineTable.vue';

export default defineComponent({
  name: 'TimelineSegment',
  components: {
    DetailLabel,
    TimelineTable
  },
  props: {
    segments: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      required: true
    },
    sidx: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      globalDateTimeFormat: globalDateTimeFormat,
      showDetails: false
    };
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails; // Update the local data property instead of mutating the prop
    },
    iconType(type: TransportType): string {
      const icon = TransportType[type];
      if (icon === 'SHIP') {
        return 'vessel size22';
      } else if (icon === 'PLANE') {
        return 'airplane size18';
      } else if (icon === 'ROAD') {
        return 'truck size22';
      }
      return 'truck size22';
    },
    isIntermediate(item) {
      return item.segmentClassifier == 'INTERMEDIATE';
    }
  }
});
